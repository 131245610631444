import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  flex
} from 'styled-system';
import { base, themed } from '../base';

const Dio = ({
  className,
  width,
  flexBox,
  flexWrap,
  ml,
  mr,
  mt,
  mb,
  m,
  fontWeight,
  fontSize,
  color,
  letterSpacing,
  alignItems,
  justifyContent,
  pr,
  pl,
  pt,
  pb,
  children,
  'a:hover,a:focus': _,
  flex,
  flexDirection,
  p,
  boxShadow,
  ...props
}) => {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

const BoxWrapper = styled(Dio)(
  base,
  themed('Box'),
  props =>
    props.flexBox &&
    css(
      { display: 'flex' },
      flexWrap,
      flexDirection,
      alignItems,
      justifyContent,
      flex,
      themed('FlexBox')
    )
);

const Box = ({ children, as, ...props }) => (
  <BoxWrapper {...props}>{children}</BoxWrapper>
);

export default Box;

Box.propTypes = {
  children: PropTypes.any.isRequired,
  /** Using this props we can convert our Box Component to a Flex Container or Component */
  flexBox: PropTypes.bool,
  // as: PropTypes.oneOf([
  //   'div',
  //   'article',
  //   'section',
  //   'address',
  //   'header',
  //   'footer',
  //   'nav',
  //   'main'
  // ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  flex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  order: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  alignSelf: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  display: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  border: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  borderTop: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  borderRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  borderBottom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  borderLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  borderColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ])
};
