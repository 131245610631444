import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { base, themed } from '../base';
import { Link as GatsbyLink } from 'gatsby';

const LinkWrapper = styled(
  ({
    className,
    width,
    flexBox,
    flexWrap,
    ml,
    mr,
    mt,
    mb,
    m,
    fontWeight,
    fontSize,
    color,
    letterSpacing,
    alignItems,
    justifyContent,
    pr,
    pl,
    pt,
    pb,
    children,
    external = false,
    'a:hover,a:focus': _,
    ...props
  }) => {
    return external ? (
      <a className={className} {...props}>
        {children}
      </a>
    ) : (
      <GatsbyLink className={className} {...props}>
        {children}
      </GatsbyLink>
    );
  }
)({ textDecoration: 'none' }, base, themed('Link'));

const Link = ({ children, display, ...props }) => (
  <LinkWrapper {...props} to={props.to || props.href}>
    {children}
  </LinkWrapper>
);

export default Link;

Link.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any.isRequired,
  ...base.propTypes,
};

Link.defaultProps = {
  // as: 'a',
  m: 0,
  display: 'inline-block',
};
